import Button from "../../Components/Button/Button";
import Layout from "../../Components/Layout/Layout";
import Video from '../../Assets/p4-video.mp4';
import './brands.scss'
import Carousel from 'better-react-carousel'
import { BrowserView, MobileView } from "react-device-detect";

function Brands() {

  const brands = [
    {'img': 'ma', 'url': 'https://www.mattressadvisor.com', 'title': 'Mattress Advisor', 'text': `Mattress Advisor’s superior testing and rating systems help make us stand apart as an authoritative reviewer of mattresses and sleep accessories. By analyzing all things sleep, from edge support and motion transfer to responsiveness and ease of set-up, we make sure you have the information you need to make the purchase you want.`, 'logo': 'ma-logo'},
    {'img': 'so', 'url': 'https://www.sleepopolis.com', 'title': 'Sleepopolis', 'text': `At Sleepopolis, we live, breathe, eat, and sleep (ha!)…sleep. By sifting through Internet noise, we cover all aspects of sleep health, whether it's keeping our readers up-to-date on the latest sleep product recalls, providing personalized sleep tips through our sleep cycle calculator, or diving deep into mattresses and bedding accessories to help people get their best sleep ever.`, 'logo': 'so-logo'},
    {'img': 'mc', 'url': 'https://www.mattressclarity.com', 'title': 'Mattress Clarity', 'text': `Mattress Clarity is a one-stop shop for mattress reviews, bedding reviews, and sleep health content. Our dedicated team of sleep health experts provide honest and unbiased reviews of the most popular sleep products on the market. Getting the right mattress and bedding for your needs can be a confusing process — we're here to provide clarity.`, 'logo': 'mc-logo'},
    {'img': 'mn', 'url': 'https://www.mattressnerd.com', 'title': 'Mattress Nerd', 'text': `Mattress Nerd is a resource that millions of people trust and turn to for answers about choosing the best mattress for their individual needs. The Mattress Nerd team is led by a group of sleep geeks who are passionate about high-quality sleep. We nerd out so you can sleep in`, 'logo': 'mn-logo'},
    {'img': 'sa', 'url': 'https://www.sleepadvisor.org', 'title': 'Sleep Advisor', 'text': `Sleep Advisor combines detailed mattress and sleep product reviews with highly informative health content to help readers achieve better rest and quality of life. The Sleep Advisor team is dedicated to providing access to the best products in the industry and answering your questions about all things sleep.`, 'logo': 'sa-logo'},
    {'img': 'ggr', 'url': 'https://www.garagegymreviews.com', 'title': 'Garage Gym Reviews', 'text': `Garage Gym Reviews is the premier online resource for those who train at home. Whether you have a garage gym, home gym, basement gym, backyard gym, or any other variation, our goal is to provide you with the information and motivation you need to continue doing so at an optimal level.`, 'logo': 'ggr-logo'},
    {'img': 'bb', 'url': 'https://www.barbend.com', 'title': 'BarBend', 'text': `BarBend is the largest and most celebrated digital brand dedicated to strength. From news in strength sports — including weightlifting, powerlifting, functional fitness, strongman, and bodybuilding — to training, nutrition, and recovery, BarBend sources the best minds and info from around the world of strength.`, 'logo': 'bb-logo'},
    {'img': 'bm', 'url': 'https://www.breakingmuscle.com', 'title': 'Breaking Muscle', 'text': `Breaking Muscle is one of the web's original homes for elite training resources. In addition to coaching tips and content, we publish some of the best news, analysis, and opinion in fitness. Breaking Muscle is the home for people looking to broaden and deepen their knowledge of fitness along the path to more active lifestyles.`, 'logo': 'bm-logo'},
  ]

  const mpThumbnails = [
    {'name': 'Sports Illustrated','url': 'https://www.si.com','title':'P4 + Sports Illustrated','img': 'si', 'text': `In partnership with SI, Pillar4’s team of health, fitness and sleep experts produce in-depth reviews, product guides, sales round-ups and more to help consumers evaluate the most popular wellness brands on the market today.`},
  ]

  const feedbacks = [
    {'author': '@DanCatz', 'brand': 'Mattress Nerd', 'text': `I am learning a lot of key differences in the mattresses. Thank you. Your videos are giving me a lot of information so I may make an educated decision.`},
    {'author': '@Willy', 'brand': 'Garage Gym Reviews', 'text': `I've been BINGING the YouTube channel, I'm about to start my garage gym journey and yall make it so damn exciting.`},
    {'author': '@Gabrielle L.', 'brand': 'Mattress Clarity', 'text': `This is great info! Now I feel like I have a better idea of how to navigate buying sheets.`},
    {'author': '@Kim M.', 'brand': 'Garage Gym Reviews', 'text': `Loved your content before, you won my heart when you said your team has dieticians too! Love it! Best of luck and will be following from Canada. Your content helped my husband and I with our garage gym!`},
    {'author': '@AnnetteH', 'brand': 'Mattress Nerd', 'text': `Y'all are the best mattress reviewers on YouTube. Please don't stop making these videos.`},
    {'author': '@Peyton G.', 'brand': 'Garage Gym Reviews', 'text': `My favorite page on Instagram and for sure the reason I strive everyday to build the best garage gym.`},
    {'author': '@Penny J.', 'brand': 'Sleepopolis', 'text': `So glad I ran across this channel. I have been struggling finding good pillows and wasted too much money. Thanks!`},
  ]
    return (
      <Layout>
        <section className="hero-overlay"></section>
          <section className="hero">
            <div className="copy">
                <h1>Our Brands</h1>
                <p>Millions of people trust our brands each day to help them make important decisions about their health and wellness.</p>
            </div>

            <div className="img-container">
              <div className="left"><img src={require('../../Assets/Brands/header4.jpg')} alt="header highlights" /></div>
              <div className="mid">
                  <div className="mid-1"><img src={require('../../Assets/Careers/header2.jpg')} alt="header highlights" /></div>
                  <div className="mid-2"><img src={require('../../Assets/Brands/header1.jpg')} alt="header highlights" /></div>
              </div>
              <div className="right"><img src={require('../../Assets/Brands/header2.jpg')} alt="header highlights" /></div>
            </div>
          </section>      

          <section className="icons">
            <div className="wrapper">
              <h2>Our brands have been featured in…</h2>
              <div className="icons-container">
                {[...Array(8)].map((i,idx) => {
                  return (
                    <div key={idx}>
                      <img alt="brand" src={require(`../../Assets/Brands/${idx+1}.png`)} />
                    </div>
                  )
                })
                }
              </div>
            </div>
          </section>

          <section className="about-1 p4-brands">
            <div className="about-1-copy">
                <h2>All P4 Brands</h2>
            </div>      
          </section>

          <section className="brands wrapper">
              {brands.map((brand, idx) => {
                return (
                  <div className="brand">
                    <div className="flipbox" key={idx}>
                      <img className="img-thumbnail" alt="thumbnail" src={require(`../../Assets/Thumbnails/${brand.img}.jpg`)} />
                      <div className="flipbox-color-overlay"></div>
                      <div className="thumbnail-logo">
                          <img alt="thumbnail-logo" src={require(`../../Assets/Thumbnails/${brand.img}-logo.png`)} />
                      </div>
                    </div>
                    <div className="brand-copy">
                      <h3>{brand.title}</h3>
                      <p>{brand.text}</p>
                      <Button path={brand.url} title={brand.title} layout="green" />
                    </div>
                  </div>
                )
              })}
          </section>

          <section className="about-1 strategic">
            <div className="about-1-copy">
                <h2>Strategic Partnerships</h2>
                <p>Pillar4 powers content production, user experience, and monetization for dozens of publishers and platforms to help even more consumers make better, more informed purchasing decisions about their health and wellness. </p>
            </div>      
          </section>

          <section className="brands wrapper">
            {mpThumbnails.map((mp, idx)=> {
              return (
                <div className="brand">
                  <div className="flipbox" key={idx}>
                        <img className="img-thumbnail" alt="thumbnail" src={require(`../../Assets/Thumbnails/${mp.img}.jpg`)} />
                        <div className="flipbox-color-overlay"></div>
                        <div className="thumbnail-logo">
                            <img alt="thumbnail-logo" src={require(`../../Assets/Thumbnails/${mp.img}-logo.png`)} />
                        </div>
                      </div>
                  <div className="brand-copy">
                    <h3>{mp.title}</h3>
                    <p>{mp.text}</p>
                    <Button path={mp.url} title={mp.name} layout="green" />
                  </div>
                </div>
              )
            })}
          </section>

          <section className="about-1">
            <div className="about-1-copy">
                <h2>Behind The Scenes of What We Love To Do</h2>
                <p>We mean business when it comes to product reviews. Our proprietary review methodologies are the foundation for our highly authoritative and objective review content. We bring consumers an in-depth and comprehensive look at each product we test. </p>
            </div>      
          </section>

          <section className="video">
            <video height="456" width="809" muted controls loop src={Video} type="video/mp4"/>
          </section>

          <section className="testimonials">
            <div className="wrapper">

              <h2>Feedback That Keeps Us Going!</h2>
              <BrowserView>
                <Carousel cols={3} rows={1} gap={'34px'} loop>
                  {feedbacks.map((feedback, idx) => {
                    return (
                      <Carousel.Item>
                        <div className="feedback-box">
                          <p>{feedback.text}</p>
                          <h3>{feedback.author}</h3>
                          <p>{feedback.brand}</p>
                        </div>
                      </Carousel.Item>
                    )
                  })}
                </Carousel>
              </BrowserView>
              <MobileView>
                <Carousel cols={1} rows={1} loop>
                  {feedbacks.map((feedback, idx) => {
                    return (
                      <Carousel.Item>
                        <div className="feedback-box">
                          <p>{feedback.text}</p>
                          <h3>{feedback.author}</h3>
                          <p>{feedback.brand}</p>
                        </div>
                      </Carousel.Item>
                    )
                  })}
                </Carousel>
              </MobileView>
            </div>
          </section>
      </Layout>
    )
  }
  
  export default Brands;