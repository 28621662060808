import Layout from '../../Components/Layout/Layout';
import Video from '../../Assets/p4-video.mp4';
import Button from '../../Components/Button/Button';

import './home.scss';

function Home() {

    const icons = [
        {'img': 'sleep','text': 'SLEEP'},
        {'img': 'active','text': 'FITNESS'},
        {'img': 'nutrition','text': 'NUTRITION'},
        {'img': 'mental','text': 'MENTAL WELLNESS'}
    ];

    const thumbnails = [
        {'img': 'mc', 'text': `Mattress Clarity is a one-stop shop for mattress reviews, bedding reviews, and sleep health content. Our dedicated team of sleep health experts provide honest and unbiased reviews of the most popular sleep products on the market. Getting the right mattress and bedding for your needs can be a confusing process — we're here to provide clarity.`},
        {'img': 'ggr', 'text': `Garage Gym Reviews is the premier online resource for those who train at home. Whether you have a garage gym, home gym, basement gym, backyard gym, or any other variation, our goal is to provide you with the information and motivation you need to continue doing so at an optimal level.`},
        {'img': 'ma', 'text': `Mattress Advisor’s superior testing and rating systems help make us stand apart as an authoritative reviewer of mattresses and sleep accessories. By analyzing all things sleep, from edge support and motion transfer to responsiveness and ease of set-up, we make sure you have the information you need to make the purchase you want.`},
        {'img': 'mn', 'text': `Mattress Nerd is a resource that millions of people trust and turn to for answers about choosing the best mattress for their individual needs. The Mattress Nerd team is led by a group of sleep geeks who are passionate about high-quality sleep. We nerd out so you can sleep in.`},
        {'img': 'sa', 'text': `Sleep Advisor combines detailed mattress and sleep product reviews with highly informative health content to help readers achieve better rest and quality of life. The Sleep Advisor team is dedicated to providing access to the best products in the industry and answering your questions about all things sleep.`},
        {'img': 'so', 'text': `At Sleepopolis, we live, breathe, eat, and sleep (ha!)…sleep. By sifting through Internet noise, we cover all aspects of sleep health, whether it's keeping our readers up-to-date on the latest sleep product recalls, providing personalized sleep tips through our sleep cycle calculator, or diving deep into mattresses and bedding accessories to help people get their best sleep ever.`},
        {'img': 'bb', 'text': `BarBend is the largest and most celebrated digital brand dedicated to strength. From news in strength sports — including weightlifting, powerlifting, functional fitness, strongman, and bodybuilding — to training, nutrition, and recovery, BarBend sources the best minds and info from around the world of strength.`},
        {'img': 'bm','text': `Breaking Muscle is one of the web's original homes for elite training resources. In addition to coaching tips and content, we publish some of the best news, analysis, and opinion in fitness. Breaking Muscle is the home for people looking to broaden and deepen their knowledge of fitness along the path to more active lifestyles.`},
    ]

    const mpThumbnails = [
        {'img': 'si', 'text': `In partnership with SI, Pillar4’s team of health, fitness and sleep experts produce in-depth reviews, product guides, sales round-ups and more to help consumers evaluate the most popular wellness brands on the market today.`},
    ]

    return (
        <Layout>
            <section className="home-hero">
                <video height="723" autoPlay={true} muted loop src={Video} type="video/mp4"/>
                <div>
                    <h1>Helping People Live Healthier Lives</h1>
                    <p>We provide access to trusted resources, in-depth product reviews, and supportive communities</p>
                </div>
            </section>

            <section className="about-p4 wrapper">
                <div className="about-p4-copy">
                    <h2>About Pillar4</h2>
                    <p>Pillar4 is a portfolio of digital brands that connects consumers to the best products across the four pillars of health and wellness.</p>
                    <Button title={'learn more'} layout={'green'} path={'/about'}/>
                </div>
                <div className="about-p4-logos-container">
                    {icons.map((icon, idx) => {
                        return (
                            <div className='logo' key={idx}>
                                <img alt="thumbnail-logo" src={require(`../../Assets/${icon.img}.jpg`)} />
                                <div className='logo-background'/>
                                <div className='logo-background-hover'/>
                                <div className='logo-background-white'/>
                                <p>{icon.text}</p>
                            </div>
                        )
                    })}
                </div>
            </section>
            <section className="our-brands">
                    <h2>Our Brands</h2>
                    <p>We own and operate a portfolio of review sites across the health and wellness space and partner with leading direct-to-consumer brands and media companies to grow their businesses.</p>
                    <Button title={'view all brands'} layout={'green'} path={'/brands'}/>
                    <div className="thumbnail-container">
                        {thumbnails.map((thumb, idx) => {
                            return (
                                <div className="flipbox" key={idx}>
                                    <img className="img-thumbnail" alt="thumbnail" src={require(`../../Assets/Thumbnails/${thumb.img}.jpg`)} />
                                    <div className="flipbox-color-overlay"></div>
                                    <div className="thumbnail-logo">
                                        <img alt="thumbnail-logo" src={require(`../../Assets/Thumbnails/${thumb.img}-logo.png`)} />
                                    </div>
                                    <p>{thumb.text}</p>
                                </div>
                            )
                        })}
                    </div>
            </section>
            <section className="our-brands">
                    <h2>Strategic Partnership</h2>
                    <p>Pillar4 powers content production, user experience, and monetization for dozens of publishers and platforms to help even more consumers make better, more informed purchasing decisions about their health and wellness.</p>
                    <div className="thumbnail-container mp">
                        {mpThumbnails.map((thumb, idx) => {
                            return (
                                <div className="flipbox" key={idx}>
                                    <img alt="thumbnail" className="img-thumbnail" src={require(`../../Assets/Thumbnails/${thumb.img}.jpg`)} />
                                    <div className="flipbox-color-overlay"></div>
                                    <div className="thumbnail-logo">
                                        <img alt="thumbnail-logo" src={require(`../../Assets/Thumbnails/${thumb.img}-logo.png`)} />
                                    </div>
                                    <p>{thumb.text}</p>
                                </div>
                            )
                        })}
                    </div>
            </section>
            <section className="our-team">
                <div className='wrapper row'>
                    <div className="copy">
                        <h2>Join Our Team</h2>
                        <p>We are a mighty team with subject matter experts in creative, branding, content strategy, SEO, paid media, and more.</p>
                        <p><strong>The opportunity to grow new skills and learn from smart people is endless.</strong></p>
                        <nav>
                            <a href="/careers">Summer Internship - Remote</a>
                            <a href="/careers">Open Role - Remote</a>
                            <a href="/careers">Open Role - Charlotte, NC</a>
                        </nav>
                        <Button title="EXPLORE ALL CAREERS" path="careers" layout="white"/>
                    </div>
                    <div className="image-great-place">
                        <img alt="carousel" src={require(`../../Assets/work.png`)} />
                    </div>
                </div>

            </section>
        </Layout>
    )
  }
  
  export default Home;