import Layout from "../../Components/Layout/Layout";
import Button from '../../Components/Button/Button';
import Carousel from 'better-react-carousel';
import './careers.scss';
import { useMediaQuery } from 'react-responsive'

function Careers() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const teams = [
    {'name': 'Content + Brand', 'img': 'content', 'text': 'Join our expert team of writers, editors, and videographers to deliver the best health and wellness content and most informative product reviews to our audiences.'},
    {'name': 'UX + Design', 'img': 'design', 'text': 'Design, test, and iterate our experiences to best serve our users and partners and deliver high-quality resources that connect people with the best products for them.'},
    {'name': 'Strategic Partnerships', 'img': 'mp', 'text': 'Build deep, strategic relationships with high-profile editorial partners and direct-to-consumer brands to educate large audiences and connect them with great products.'},
    {'name': 'Engineering', 'img': 'eng', 'text': 'Work with our full-stack team to build and improve our online properties and systems. Take ownership of implementing innovative solutions leveraging cutting edge technology.'},
    {'name': 'Digital Marketing', 'img': 'digital', 'text': 'Deliver the best, most helpful content to our audience wherever they are in their buying journey by optimizing our SEO, paid media, email, and social media strategies.'},
    {'name': 'People', 'img': 'people', 'text': 'Expand and develop our fast-growing team. By aligning purposeful areas of development with our business needs, you can help us become the best version of ourselves.'},
  ]
  const benefits = [
    {'title': 'HEALTH INSURANCE', 'img': '8'},
    {'title': 'DENTAL INSURANCE', 'img': '4'},
    {'title': 'FLEXIBLE PAID TIME OFF', 'img': '3'},
    {'title': 'MONTHLY WELLNESS STIPEND', 'img': '7'},
    {'title': 'VISION INSURANCE', 'img': '1'},
    {'title': '401K MATCHING', 'img': '5'},
    {'title': 'PHONE REIMBURSEMENT', 'img': '2'},
    {'title': 'CHARITABLE MATCHING', 'img': '6'},
  ]

  const leadership = [
    {'name': 'Megan Knight', 'title': 'Recruiting Manager', 'email': 'megan.knight@pillarfour.com', 'img': 'm', 'social': 'https://www.linkedin.com/in/meganknightt/'},
    {'name': 'Rebecca Liebow', 'title': 'Talent Associate', 'email': 'rebecca.liebow@pillarfour.com', 'img': 'r', 'social': 'https://www.linkedin.com/in/rebecca-liebow-159334187/'},
  ]

  const testimonials = [
    {'name': 'Kate Meier', 'role': 'Head of Content, GGR', 'highlight': '', 'quote': '', 'video': 'https://www.youtube.com/embed/94hUuTy6eag?si=BXRRz8VSrdBxCZqc', 'image': 'katemeier'},
    {'name': 'Anna Cradle', 'role': 'Sr. Customer Analytics Associate', 'highlight': '', 'quote': '', 'video': 'https://www.youtube.com/embed/P_CNN5ovu9w?si=uSGN2eEuHBFkdVTG', 'image': 'annacradle'},
    {'name': 'Granth Smith', 'role': 'Sr Analyst, Performance Marketing', 'highlight': '', 'quote': '', 'video': 'https://www.youtube.com/embed/Thm3uoTQQdY?si=GBR5KdXcrkT41kkv', 'image': 'grantsmith'},
    {'name': 'Hailey Moore', 'role': 'SEO Analyst', 'highlight': '', 'quote': '', 'video': 'https://www.youtube.com/embed/6W8IKXxIkeI?si=SOMd869op9twGF48', 'image': 'haileymoore'},
    {'name': 'Monawar Chabayta', 'role': 'SEO Associate, GGR', 'highlight': 'The values and mission of Pillar4', 'quote': `While I've always loved SEO, the values and mission of Pillar 4 to "Help People Live Healthier Lives" piqued my attention even more.`, 'video': '', 'image': 'mona'},
    {'name': 'Corey Bryan', 'role': 'Video Producer', 'highlight': '', 'quote': '', 'video': 'https://www.youtube.com/embed/HM_hCBou2QY', 'image': 'corey'},
    {'name': 'Jessica Banks', 'role': 'Health Editor', 'highlight': 'The genuine enthusiasm', 'quote': `The genuine enthusiasm existing team members had for the company—it's rare to see that kind of passion toward a company, and it confirmed that P4 really cares about its employees and its content.`, 'video': '', 'image': 'jessica'},
    {'name': 'Loren Bullock', 'role': 'Editor', 'highlight': '', 'quote': '', 'video': 'https://www.youtube.com/embed/7wzZKgw2sAU', 'image': 'loren'},
    {'name': 'Jill Zwarensteyn', 'role': 'Editor', 'highlight': 'P4 is incredibly welcoming', 'quote': `P4 is incredibly welcoming. They embrace a collaborative, supportive, and positive work environment. Since I began my professional writing career, it's been a dream of mine to eventually move into an editor position. I couldn't pass up an opportunity to step into my dream job with a fantastic team.`, 'video': '', 'image': 'jill'},
    {'name': 'Ben Zahavi', 'role': 'UX Researcher', 'highlight': '', 'quote': '', 'video': 'https://www.youtube.com/embed/S9z8aFt4API', 'image': 'ben'},
    {'name': 'Carlo Barajas', 'role': 'Director of SEO', 'highlight': '', 'quote': '', 'video': 'https://www.youtube.com/embed/o8fvVn_hQfA', 'image': 'carlo'},
    {'name': 'Jared Myers', 'role': 'UX & Product Research Associate', 'highlight': '', 'quote': '', 'video': 'https://www.youtube.com/embed/7VnFdQ2UBpM', 'image': 'jared'},
    {'name': 'Madison Muire', 'role': 'Sr SEO Associate', 'highlight': '', 'quote': '', 'video': 'https://www.youtube.com/embed/pqjvxtVwaR8', 'image': 'madison'},
  ]

    return (
      <Layout>
          <section className="hero-overlay"></section>
          <section className="hero">
            <div className="copy">
                <h1>Careers at P4</h1>
                <p>Join our fast-growing team and drive your career in digital media and marketing.</p>
            </div>

            <div className="img-container">
              <div className="left"><img src={require('../../Assets/Careers/header1.jpg')} alt="header highlights" /></div>
              <div className="mid">
                  <div className="mid-1"><img src={require('../../Assets/Careers/header2.jpg')} alt="header highlights" /></div>
                  <div className="mid-2"><img src={require('../../Assets/Careers/header4.jpg')} alt="header highlights" /></div>
              </div>
              <div className="right"><img src={require('../../Assets/Careers/header3.jpg')} alt="header highlights" /></div>
            </div>
          </section>   

          <section className="commitment">
            <div className="wrapper row">
              <div className="commitment-copy">
                <h2>The Pillar4 Commitment</h2>
                <p>We are committed to finding and developing the best people and creating an enduring platform to accelerate their careers.</p>
                <div className="buttons">
                  <Button title="View open roles" path="https://pillar4media.breezy.hr/" layout="white"/>
                  <Button title="get in contact" path="/contact" layout="white"/>
                </div>
              </div>

              <div className="commitment-pic">
                <img alt="carousel" src={require(`../../Assets/work.png`)} />
              </div>
            </div>
          </section>  

          <section className="about-1">
            <div className="about-1-copy">
                <h2>Explore our teams</h2>
                <p>We’re looking for curious, capable people to join Pillar4 and help us revolutionize how people make decisions around health and wellness.</p>
            </div>      
          </section>

          <section className="teams wrapper">
          {teams.map((thumb, idx) => {
            return (
                <div className="flipbox" key={idx}>
                    <h3>{thumb.name}</h3>
                    <div className="logo-container">
                      <img alt="thumbnail" className="img-thumbnail" src={require(`../../Assets/Careers/${thumb.img}.png`)} />
                    </div>
                    <div className="flipbox-color-overlay">
                      <h3>{thumb.name}</h3>
                      <p>{thumb.text}</p>
                    </div>
                </div>
            )
          })}
          </section>

          <section className="map">
            <div className="copy">
              <h3>Pillar4 has operating hubs in Charlotte, Raleigh, Austin, Springfield</h3>
              <p>Our teams span the country from coast to coast</p>
            </div>
            <div className="map-container">
              <img src={require("../../Assets/About/map.png")} />
            </div>
          </section>


          <section className="testimonials">
            <div className="wrapper">
              <h2>What our current and past employees say about P4</h2>
              <Carousel cols={isTabletOrMobile ? 1 : 2} rows={1} gap={'20px'} loop>
                {testimonials.map((testimonial, idx) => {
                  return (
                    <Carousel.Item>
                      <div className="testimonial" key={idx}>
                          <div className="details">
                            <div className="headshot">
                              <img src={require(`../../Assets/Careers/Testimonial/${testimonial.image}.jpg`)} />
                            </div>
                            <div>
                              <p className="name">{testimonial.name}</p>
                              <p className="role">{testimonial.role}</p>
                            </div>
                          </div>
                        {!testimonial.video && 
                          <div className="info">
                            <p className="highlight">{testimonial.highlight}</p>
                            <p>{testimonial.quote}</p>
                          </div>
                        }
                        {testimonial.video && 
                          <div className="yt-frame">
                            <iframe src={testimonial.video}/>
                          </div>
                        }
                        </div>
                    </Carousel.Item>
                  )
                })}
              </Carousel>
            </div>
          </section>

          <section className="about-1">
            <div className="about-1-copy">
                <h2>Benefits that fit you</h2>
                <p>Our benefits are designed to support the health of our team. From a monthly wellness stipend for gyms, grocery delivery, massages, and more to flexible PTO, we prioritize our people.</p>
            </div>      
          </section>

          <section className="benefits">
            {benefits.map((benefit, idx) => {
              return (
                <div key={idx}>
                  <div><img src={require(`../../Assets/Careers/Icons/${benefit.img}.png`)}/></div>
                  <h5>{benefit.title}</h5>
                </div>
              )
            })}
          </section>

          <section className="process">
            <div className="wrapper">
              <h2>Our Hiring Process</h2>
              <div>
                <div className="step">
                  <h4>01</h4>
                  <div>
                    <h6>Apply</h6>
                    <p>Apply To begin the application process, you can apply for a position by clicking the “Apply” button on the position’s job description page. This will take you into our application portal, Breezy, where you will have the ability to upload your resume. In addition to your employment history, you may be asked to answer questions specific to the role for which you are applying. While the process may differ slightly for different roles or teams, the same basics apply whether you’re applying for a role in tech, marketing, an internship, or a leadership position.</p>
                  </div>
                </div>
                <div className="step">
                  <h4>02</h4>
                  <div>
                    <h6>Phone interview</h6>
                    <p>If our recruitment team believes you might be a match for a job you’ve applied for, you’ll enter our recruitment process. One of our recruiters will reach out to you for a phone interview. In this interview, we’ll discuss the role, qualifications needed to be successful, and the opportunity to get to know you better.</p>
                  </div>
                </div>
                <div className="step">
                  <h4>03</h4>
                  <div>
                    <h6>Virtual or In-Person Interview</h6>
                    <p>During this stage of the recruitment process you will meet P4 teammates and go through 2-3 interviews. These teammates will either be on the same team that you are interested in joining or in a different part of the business, but add valuable perspectives. These conversations are meant to be friendly, warm, and informative. The team will dig a little deeper into your experience and ensure that you are aligned to our culture and core values. You will have the opportunity to get to know us better and ask questions as well. Our goal is to create an equitable and inclusive experience where candidates from a wide variety of backgrounds have an opportunity to succeed. Every candidate is assessed using clear rubrics to ensure all applicants are evaluated from the same perspective, allowing their distinctiveness to emerge.</p>
                  </div>
                </div>
                <div className="step">
                  <h4>04</h4>
                  <div>
                    <h6>Technical assessment (depending on role) </h6>
                    <p>Depending on the role, you may be asked to provide supporting materials, such as sharing a portfolio, writing sample, or a take-home assessment. This will help our hiring team vet the skills required to be successful. If the role requires an assessment, you will be notified by our talent team.</p>
                  </div>
                </div>
                <div className="step">
                  <h4>05</h4>
                  <div>
                    <h6>Decision and Offer</h6>
                    <p>After your interviews are complete, our team will come together to make a final decision. If the team decides to move forward, a written offer of employment will be created, and a verbal offer will be extended. Once the offer is accepted by you, a formal offer and contract letter will be drafted for your signature. At this stage, and at any stage of the process, if the hiring team decides that this is not the right role or fit for you, a recruiter will reach out to let you know. We will not leave you wondering! While the timeframe may vary by location and circumstance, our goal is to get you started as soon as possible so we can welcome you into the Pillar4 Family!</p>
                  </div>
                </div>
              </div>

            </div>
          </section>

          <section className="about-1">
            <div className="about-1-copy">
                <h2>Our Recruitment Team</h2>
                <p>P4 thrive is a company-wide initiative with, you guessed it, four pillars aimed at giving back to our team and the communities in which we live and work.</p>
            </div>      
          </section>

          <section className="leadership" style={{maxWidth: "1280px", margin: "0 auto"}}>
                    {leadership.map((leader,idx) => {
                        return (
                            <div className="headshot-div">
                                <div className='logo' key={idx}>
                                    <img alt="thumbnail-logo" src={require(`../../Assets/Careers/Icons/${leader.img}.jpg`)} />
                                    <div className='logo-background'/>
                                    <div className='logo-background-white'/>
                                </div>
                                <div className="leader-copy">
                                    <h5>{leader.name}</h5>
                                    <p>{leader.title}</p>
                                    <Button path={"mailto:" + leader.email} layout="green" title="EMAIL" />
                                    <a href={leader.social}>LinkedIn Profile</a>
                                </div>
                            </div>
                        )
                    })}
                </section>
      </Layout>
    )
  }
  
  export default Careers;