import Layout from "../../Components/Layout/Layout";
import Button from '../../Components/Button/Button';

import './contact.scss';

function Contact() {
    return (
      <Layout>
           <section className="hero-overlay contact-us"></section>
          <section className="hero contact-us">
            <div className="copy">
                <h1>Contact Us</h1>
                <p>Interested in learning more about Pillar4? Have a question about one of our brands? Curious about partnership opportunities? We want to hear from you!</p>
            </div>
          </section>  

          <section className="contact-form">
            <div className="left">
              <div className="header">
                <div><img src={require('../../Assets/p4-icon.png')} alt="logo" /></div>
                <p>Simply fill out the form below and we’ll put you in touch with the right teammates.</p>
              </div>
              <form action="mailto:info@pillarfour.com" method="POST" encType="multipart/form-data" name="emailform">
                <div>
                  <div>
                    <label>Name</label>
                    <input required type="text"></input>
                  </div>
                  <div>
                    <label>Company</label>
                    <input required type="text"></input>
                  </div>
                </div>
                <div>
                  <div>
                    <label>Title</label>
                    <input required type="text"></input>
                  </div>
                  <div>
                    <label>Email</label>
                    <input required type="email"></input>
                  </div>
                </div>
                <label>Message</label>
                <textarea required name="msg"></textarea>
                <input type="submit" value="Send"></input>
              </form>
            </div>

            <div className="right">
              <h5>Connect with us</h5>
              <div></div>
              <div>
                <h6>Charlotte Office:</h6>
                <p>2101 Thrift Road</p> 
                <p>Charlotte, NC 28208</p>
              </div>
            </div>

          </section>

          {/* <section className="map">
            <div className="copy">
              <h3>Our Locations</h3>
              <p>Pillar4 has operating hubs in Charlotte and Raleigh, NC, with additional product testing studios in Springfield, MO and Austin, TX. We also have teammates located across the United States.</p>
              <p>Get Directions:</p>
              <div>
                <Button title="Charlotte" path="https://www.google.com/maps/dir//800+Westmere+Ave+STE+100,+Charlotte,+NC+28208/@35.2276013,-80.8437821,13.38z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8856a1d31e28a369:0x456d207e1947d6bb!2m2!1d-80.8559823!2d35.2279644" layout="green"/>
              </div>
            </div>
            <div className="map-container">
              <img src={require("../../Assets/About/map.png")} />
            </div>
          </section> */}
      </Layout>
    )
  }
  
  export default Contact;