import { Link } from "react-router-dom";
import Logo from '../../Assets/pillarfour-logo.png';
import { useState, useEffect } from 'react';


import './header.scss';

export default function Header() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const [mobile, setMobile] = useState(false);

    const links = [
        {'title':'Home', 'path': '/'}, 
        {'title':'About', 'path': '/about'}, 
        {'title':'Careers', 'path': '/careers'}, 
        {'title':'Our Brands', 'path': '/brands'}, 
        {'title':'Contact Us', 'path': '/contact'}, 
    ];
    return (
        <header>
            <div className="wrapper row">
                <a href="/" className="nav-logo-container">
                    <img src={Logo} alt="site-logo" />
                </a>
                <div className="mobile-nav">
                    <img alt="menu-icon" id="hamburger-icon" src={require('../../Assets/hamburger.png')} onClick={() => setMobile(!mobile)}/>
                    {mobile && 
                        <nav>
                            {links.map((link, idx) => {
                                return <Link key={idx} to={link.path}>{link.title}</Link>
                            })}
                        </nav>
                    }
                </div>
                <nav>
                    {links.map((link, idx) => {
                        return <Link key={idx} to={link.path}>{link.title}</Link>
                    })}
                </nav>
            </div>
        </header>
    )
}
