import './footer.scss';

export default function Footer() {
    const navLinks = [
        {'title':'Home', 'path': '/'}, 
        {'title':'About', 'path': '/about'}, 
        {'title':'Careers', 'path': '/careers'}, 
        {'title':'Our Brands', 'path': '/brands'}, 
        {'title':'Contact Us', 'path': '/contact'}
    ];

    const brandLinks = [
        {'title': 'Mattress Nerd','path': 'https://www.mattressnerd.com'},
        {'title': 'Sleepopolis','path': 'https://www.sleepopolis.com'},
        {'title': 'Mattress Clarity','path': 'https://www.mattressclarity.com'},
        {'title': 'Mattress Advisor','path': 'https://www.mattressadvisor.com'},
        {'title': 'Sleep Advisor','path': 'https://www.sleepadvisor.org'},
        {'title': 'Garage Gym Reviews','path': 'https://www.garagegymreviews.com'},
        {'title': 'BarBend','path': 'https://www.barbend.com'},
        {'title': 'Breaking Muscle','path': 'https://www.breakingmuscle.com'},
    ]

    const footerSections = [
        {
            'title': 'Pillar4 Media',
            'links': [...navLinks]
        },
        {
            'title': 'Our Brands',
            'links': [...brandLinks]
        },
        {
            'title': 'Contact Us',
            'text': 'info@pillarfour.com'
        },

    ]
    return (
        <footer>
            <div className="wrapper footer-main">
                {footerSections.map((section, idx) => {
                    return (
                        <div className="footer-column" key={idx}>
                            <h2>{section.title}</h2>
                            {section.text && <p>{section.text}</p>}
                            {section.links && <nav className="footer-nav">{
                                section.links.map((item, idx) => {
                                    return (
                                        <a target={section.title !== 'Pillar4 Media' && '_blank'  } key={idx} href={item.path}>{item.title}</a>
                                    )
                                })}</nav>}
                        </div>
                    )
                })}
            </div>
        </footer>
    )
}
