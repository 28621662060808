import Layout from '../../Components/Layout/Layout';
import Carousel from 'better-react-carousel'
import './about.scss';

function About() {
    const processIcons = [
        {'img': 'board','title':'Hands-on product testing','text': 'Across our three studios, our team of industry experts tests products first-hand to review the best in mattresses, fitness equipment, and more.'},
        {'img': 'chain','title':'Editorial partnerships','text': 'We work with high-profile media partners to bring our in-depth product review content to tens of millions of new readers.'},
        {'img': 'write','title':'Original content','text': 'We help our audience of tens of millions monthly readers practice healthy habits day and night with our comprehensive resources and buying guides.'},
        {'img': 'people','title':'Expert advisory panel','text': 'Our expert panel of doctors, specialists, and industry veterans provide science-backed information to help make our content trustworthy and authoritative.'},
    ];
    
    const corevalues = [
        {'img': '1','title':'Get After It','text': 'Across our three studios, our team of industry experts tests products first-hand to review the best in mattresses, fitness equipment, and more.','values': ['Exemplify passion and edge.', 'Show strong initiative.', 'Play to win.']},
        {'img': '2','title':'Act With Focus','text': 'We work with high-profile media partners to bring our in-depth product review content to tens of millions of new readers.','values': ['Manage time well.', 'Deliver on inputs.', 'Drive meaningful outcomes.']},
        {'img': '3','title':'Grow Without Limits','text': 'We help our audience of tens of millions monthly readers practice healthy habits day and night with our comprehensive resources and buying guides.','values': ['Stay curious.', 'Challenge the status quo.', 'Practice candor.']},
        {'img': '4','title':'Build the Team','text': 'Our expert panel of doctors, specialists, and industry veterans provide science-backed information to help make our content trustworthy and authoritative.','values': ['Energize others.', 'Carry the culture.', 'Own your development.']},
        {'img': '5','title':'Obsess Over Customers','text': 'We help our audience of tens of millions monthly readers practice healthy habits day and night with our comprehensive resources and buying guides.','values': ['Earn consumer trust.', 'Solve real problems.', 'Execute with empathy.']},
        {'img': '6','title':'Commit to Service','text': 'Our expert panel of doctors, specialists, and industry veterans provide science-backed information to help make our content trustworthy and authoritative.','values': ['Ask for help and help others.', 'Serve in our communities.', 'Be a force for good.']},
    ];

    const leadership = [
        {'name': 'Todd Alexander', 'title': 'CEO', 'img': 'todd', 'social' : 'https://www.linkedin.com/in/todd-alexander-9363986/'},
        {'name': 'Jeff Ross', 'title': 'CFO', 'img': 'jeff', 'social' : 'https://www.linkedin.com/in/jeffross7641/'},
        {'name': 'Alesandra Woolley', 'title': 'President, Brand, Content', 'img': 'alesandra', 'social' : 'https://www.linkedin.com/in/alesandra-woolley-a28870b/'},
        {'name': 'Tyler Moyer', 'title': 'President, Fitness', 'img': 'tyler', 'social' : 'https://www.linkedin.com/in/tyler-moyer-14b02a7/'},
        {'name': 'Brian Winders', 'title': 'President, Digital Partnerships', 'img': 'brian', 'social' : 'https://www.linkedin.com/in/brianwinders/'},
        {'name': 'Aaron Greene', 'title': 'President, Design, Engineering, UXRP', 'img': 'aaron', 'social' : 'https://www.linkedin.com/in/aaron-greene-9289b341/'},
        {'name': 'Kenny Kline', 'title': 'President of Fitness', 'img': 'kenny', 'social' : 'https://www.linkedin.com/in/kennykline/'},
    ]

    const socialService = [
        {'img': 'move','title': 'P4 Moves', 'subtitle': 'To be healthier', 'text': `Through quarterly fitness challenges and a $125 monthly wellness stipend for the gym, house cleaning, grocery deliveries, and more, we encourage our team to focus on both physical and mental health.
        To emphasize our commitment to wellness, our team has flexible paid time off so we can recharge and return to work invigorated and inspired.`},
        {'img': 'give','title': 'P4 Gives', 'subtitle': 'To serve our communities', 'text': `P4 gives responds to the critical needs of our communities through large-scale social impact campaigns and quarterly opportunities to work with mission-aligned organizations, including Beds for Kids and The Green Chair Project.
        We also offer a dollar-for-dollar match on charitable donations up to $1,000 a year, so we can invest in the causes you care about.`},
        {'img': 'read','title': 'P4 Reads', 'subtitle': 'To continue to learn', 'text': `When you have a group of smart, curious people, you’ve got to take advantage! P4 reads is a company-wide book club tackling one book a quarter.
        From novels to articles and podcasts, anything that sparks discussion and reflection is up for nomination.`},
        {'img': 'hang','title': 'P4 Hangs', 'subtitle': 'To stay connected', 'text': `The team has a high bar for excellence, but also a real interest in each other and making work fun.
        From happy hours and volunteering events to our daily battle rhythm that keeps us connected, we genuinely enjoy spending time with each other.`}
    ]

    return (
        <Layout>
            <section className="hero-overlay"></section>
            <section className="hero">
                <div className="copy">
                    <h1>About Pillar4</h1>
                    <p>We help people live healthier lives by providing access to trusted resources, in-depth product reviews, and supportive communities.</p>
                </div>

                <div className="img-container">
                    <div className="left"><img src={require('../../Assets/About/header1.jpg')} alt="header highlights" /></div>
                    <div className="mid">
                        <div className="mid-1"><img src={require('../../Assets/About/header2.jpg')} alt="header highlights" /></div>
                        <div className="mid-2"><img src={require('../../Assets/About/ggr.jpg')} alt="header highlights" /></div>
                    </div>
                    <div className="right"><img src={require('../../Assets/About/header3.jpg')} alt="header highlights" /></div>
                </div>
                </section>
                <section className="about-1">
                    <div className="about-1-copy">
                        <h2>Cutting Through The Noise</h2>
                        <p>Our expert reviews help consumers make more informed purchasing decisions and navigate the overwhelming health and wellness marketplace.</p>
                    </div>
                </section>
                <section className="our-process">
                    <div className='wrapper'>
                        <div className="our-process-container-1">
                            <div className="our-process-copy">
                                <h2>Our Process</h2>
                                <p>Hands-on testing, industry expertise, and proprietary technology help us create unique digital solutions to benefit consumers and add value to brand partners.</p>
                            </div>
                        </div>
                        <div className="our-process-container-2">
                            {processIcons.map((set, idx) => {
                                return (
                                    <div key={idx} className="process-group">
                                        <div className="process-group-img">
                                            <img alt="process-group-icon" src={require(`../../Assets/About/${set.img}.svg`)}/>
                                        </div>
                                        <div className="process-group-copy">
                                            <h3>{set.title}</h3>
                                            <p>{set.text}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </section>
                <section className="about-1">
                    <div className="about-1-copy">
                        <h2>Pillar4 has operating hubs in Charlotte, Raleigh, Austin, Springfield</h2>
                        <p>Our teams span the country from coast to coast</p>
                        <div>
                            <img src={require('../../Assets/About/map.png')} alt="map"/>
                        </div>
                    </div>
                </section>

                <section className="image-crl wrapper">
                <Carousel cols={6} rows={1} gap={10} loop>
                    <Carousel.Item>
                        <img alt="carousel item" width="100%"height="198px" src={require('../../Assets/About/Carousel/1.jpg')} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img alt="carousel item" width="100%"height="198px" src={require('../../Assets/About/Carousel/2.png')} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img alt="carousel item" width="100%"height="198px" src={require('../../Assets/About/Carousel/3.png')} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img alt="carousel item" width="100%"height="198px" src={require('../../Assets/About/Carousel/4.png')} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img alt="carousel item" width="100%"height="198px" src={require('../../Assets/About/Carousel/5.png')} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img alt="carousel item" width="100%"height="198px" src={require('../../Assets/About/Carousel/6.jpg')} />
                    </Carousel.Item>
                    </Carousel>
                </section>

                <section className="our-process">
                    <div className='wrapper'>
                        <div className="our-process-container-1">
                            <div className="our-process-copy">
                                <h2>Our Core Values</h2>
                                <p>Our values embody how we work – our attitudes and behaviors, and who we do it for – the people we serve. More than just a poster on the wall, we live and breathe our values each and every day.</p>
                            </div>
                        </div>
                        <div className="our-process-container-2">
                            {corevalues.map((set, idx) => {
                                return (
                                    <div key={idx} className="process-group">
                                        <div className="process-group-img">
                                            <img alt="process-group-icon" src={require(`../../Assets/About/${set.img}.png`)}/>
                                        </div>
                                        <div className="process-group-copy">
                                            <h3>{set.title}</h3>
                                            <div className="values-container">
                                                {set.values.map((value, idx) => {
                                                    return <p key={idx} >{value}</p>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>

                <section className="about-1 with-pic">
                    <div className="about-1-copy">
                        <h2>We thrive when we serve one another</h2>
                        <p>P4 Thrive is a company-wide initiative with four pillars aimed at staying connected with each other and giving back to our team and the communities in which we live and work.</p>
                    </div>
                    <div className="about-pic-container">
                        {socialService.map((thumb, idx) => {
                            return (
                                <div className="flipbox" key={idx}>
                                    <img className="img-thumbnail" alt="thumbnail" src={require(`../../Assets/About/Social/${thumb.img}.jpg`)} />
                                    <div className="flipbox-color-overlay"></div>
                                    <div className="thumbnail-text">
                                        <h5>{thumb.title}</h5>
                                        <h6>{thumb.subtitle}</h6>
                                        <p>{thumb.text}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </section>

        
                {/* <section className="our-process">
                    <div className="our-process-container-1">
                        <div className="our-process-copy">
                            <h2>Social Mission</h2>
                            <p>Hold for photos (FDNY, Green Chair, others) and placeholder copy that showcases our giving initiatives and commitment to social impact / making a difference in our communities.</p>
                        </div>
                    </div>
                    <div className="our-process-container-2">
                        
                    </div>
                </section> */}

                <section className="about-1">
                    <div className="about-1-copy">
                        <h2>Our Leadership Team</h2>
                    </div>
                </section>
                
                <section className="leadership wrapper">
                    {leadership.map((leader,idx) => {
                        return (
                            <div className="headshot-div">
                                <div className='logo' key={idx}>
                                    <img alt="thumbnail-logo" src={require(`../../Assets/About/${leader.img}.jpeg`)} />
                                    <div className='logo-background'/>
                                    <div className='logo-background-white'/>
                                </div>
                                <div className="leader-copy">
                                    <h5>{leader.name}</h5>
                                    <p>{leader.title}</p>
                                    <a target="_blank" href={leader.social}>LinkedIn Profile</a>
                                </div>
                            </div>
                        )
                    })}
                </section>
        </Layout>
    )
  }
  
export default About;