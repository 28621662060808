
import './global.scss';
import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import About from './Pages/About/About'
import Careers from './Pages/Careers/Careers'
import Brands from './Pages/Brands/Brands'
import Contact from './Pages/Contact/Contact'
import Home from './Pages/Home/Home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/brands" element={<Brands />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
